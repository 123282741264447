import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import { useIntl } from '../../../../../plugins/publicis-gatsby-plugin-i18n';
import AudioComponent from '../../../../components/AudioComponent/AudioComponent';
import IntroPage from '../../../../components/ContentType/IntroPage/IntroPage';
import VideoSection from '../../../../components/ContentType/Section/VideoSection/VideoSection';
import TextVisuelTwoCol from '../../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import PageBannerStyle from '../../../../components/PageBannerStyle/PageBannerStyle';
import ScrollPageNav from '../../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../../components/Seo/Seo';
import useMedias from '../../../../hooks/useMedias';
import Layout from '../../../../Layout';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitlePage from '../../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';
import KeyNumbers from '../../../../components/KeyNumbers/KeyNumbers';
import VisuelTextOverlappedBlock from '../../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import { getBreadCrumb, getReOrderedCarouselSlides, getSectionBG, getTextColor, removeHtmlTag, tradDroiteGauche } from '../../../../Utils/Utils';

import './styles.scss';
import DocumentSection from '../../../../components/ContentType/Section/DocumentSection/DocumentSection';
import SliderComponent from '../../../../components/SliderComponent/SliderComponent';
import { Button } from '../../../../Molecules/Button/Button';
import Carousel from '../../../../components/Carousel/Carousel';
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';

let classNames = require('classnames');

const InnovationProjets = ({ data, pageContext }) => {
  const intl = useIntl();
  const { processUrl, getImage, getDocument } = useMedias();
  const { locale } = pageContext;
  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0].node;
  const documentsArray = data?.allDocuments?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  let searchQuery = '';

  const [innerNavList, setInnerNavList] = useState([]);
  const [urlParam, setUrlParam] = useState('');
  const [allText, setAllText] = useState('');

  let sectionArr = [];
  let sectionCount = 0;
  let globalText = '';
  const metaTags = pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });

  const slider_realisation_settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1, //no slider on desktop
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(
      removeHtmlTag(
        `${pageData.title}.${pageData.body?.processed}.${pageData.field_text_2?.processed}.${globalText}.`
      )
    );
  }, [globalText]);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof location !== 'undefined') {
      searchQuery = new URLSearchParams(location?.search).get('anchor') || '';
      setUrlParam(searchQuery);
    }
  }, []);

  return (
    <Layout>
      <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames(
        'page_template',
        'n-2_innovation_projets',
        !pageData?.field_padding && 'zero_bottom_padding'
      )}>
        <ScrollPageNav data={innerNavList} scrollTo={urlParam} />
        <PageBannerStyle
          visuel={getImage(
            imagesArray,
            pageData?.relationships?.field_image?.drupal_internal__mid
          )}
          alt={pageData.relationships?.field_image?.field_media_image?.alt || ''}
          scroll={true}
          breadcrumbData={{
            //grandParentPage: { title: 'Acceuile', url: '/' },
            parentPage: {
              title: breadCrumb?.parent?.name,
              url: breadCrumb?.parent?.link,
            },
            currentPage: {
              title: breadCrumb?.current?.name,
              url: breadCrumb?.current?.link,
            },
            locale: pageData.langcode,
          }}
        >
          <div className="wrapper_page">
            <TitlePage color="color_white" title={pageData.title} />
            {pageData.field_text_2?.processed ? (
              <div className="col_2_fix">
                <div className="col">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.body?.processed,
                    }}
                  />
                </div>
                <div className="col">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.field_text_2?.processed,
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="col_single">
                <div
                  dangerouslySetInnerHTML={{ __html: pageData.body?.processed }}
                />
              </div>
            )}

            {allText && pageData.field_version_audio && allText.length > 30 && (
              <AudioComponent
                text={intl.formatMessage({
                  id: 'detailsActivities.button.listen.label',
                })}
                textToRead={allText}
              />
            )}
          </div>
        </PageBannerStyle>
        {pageData.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__block_actualites':
              if (block.field_title?.processed) {
                sectionCount++;
                sectionArr.push({
                  title: block.field_title?.processed,
                  section:
                    'section-' +
                    sectionCount,

                });
              }
              globalText +=
                block.field_title?.processed + '.' + block?.body?.processed;
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section-' + (block.field_title?.processed ? sectionCount : '__'),
                  )}
                  key={i}
                >
                  <div className="wrapper_page">
                    <TitleSection
                      title={block.field_title?.processed}
                      description={block.body?.processed}
                      type={block?.field_yellow_arrow ? 'line' : 'no_line'}
                    />
                  </div>
                </section>
              );

            case 'block_content__basic':
              if (block.field_title?.processed) {
                sectionCount++;
                sectionArr.push({
                  title: block.field_title?.processed,
                  section:
                    'section-' +
                    sectionCount,
                });
              }
              globalText += block?.body?.processed;
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section-' + (block.field_title?.processed ? sectionCount : '__'),
                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block.field_backgroud_color)}
                    bottomInfinite={block.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                  >
                    <div className="wrapper_page_xs">
                      {
                        block.field_title_type === 'theme_2' ?
                          <TitleBlock
                            title={block.field_title?.processed}
                            subtitle={block.field_subtitle?.processed}
                            rtl={block?.field_display_rtl}
                            color={'color_dark_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                            headingTag='h3'
                            titleBackground={false}
                          />
                          :
                          <TitleSection
                            title={block.field_title?.processed}
                            subtitle={block.field_subtitle?.processed}
                            rtl={block?.field_display_rtl}
                            h2color={getTextColor(getSectionBG(block.field_backgroud_color))}
                            h4color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                            theme={block.field_title_type}
                          />
                      }
                    </div>
                    <div className="wrapper_page_xs">
                      <TextVisuelTwoCol
                        colorDescription={'color_' + (getTextColor(getSectionBG(block?.field_backgroud_color) || 'bleu'))}
                        colorTitle={
                          block.arrowColor === 'theme_1'
                            ? 'color_yellow'
                            : 'color_dark_bleu'
                        }
                        extremeLeft={
                          block?.field_no_padding &&
                            block?.field_alignment === 'left'
                            ? true
                            : false
                        }
                        extremeRight={
                          block?.field_no_padding &&
                            block?.field_alignment === 'right'
                            ? true
                            : false
                        }
                        orderInverse={
                          block?.field_alignment === 'right'
                            ? true
                            : false
                        }
                        alignTop={true}
                        visuel={block?.relationships?.field_image ? getImage(
                          imagesArray,
                          block?.relationships?.field_image?.drupal_internal__mid
                        ) : null}
                        alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                        text={block.body?.processed}
                        cta={{
                          ctaText: block.field_link?.title,
                          ctaLink: block.field_link?.url,
                        }}
                      />
                    </div>
                  </ShapeBackground>
                </section>
              );
            case 'block_content__block_video':
              const videoContent = {
                content: [
                  {
                    type: 'video',
                    link: block.field_link?.uri,
                    title: removeHtmlTag(block.field_title?.processed),
                    image: getImage(
                      imagesArray,
                      block.relationships?.field_image?.drupal_internal__mid
                    ),
                    description: block.body?.processed
                  },
                ],
              };

              return (
                <section className='section_content'>
                  <VideoSection videoDetails={videoContent}></VideoSection>
                </section>
              );

            case 'block_content__block_document':
              let tempFileArr = [];
              block.relationships?.field_file.forEach((file) => {
                tempFileArr.push(
                  getDocument(documentsArray, file?.drupal_internal__mid)
                );
              });
              const documentContent = {
                year: block.node?.relationships?.field_year?.name,
                title: removeHtmlTag(block?.field_title?.processed),
                category: block?.field_category,
                content: [
                  {
                    type: 'document',
                    files: tempFileArr,
                  },
                ],
              };

              return (
                <>
                  <DocumentSection
                    documentDetails={documentContent}
                  ></DocumentSection>
                </>
              );

            case 'block_content__block_slider_nos_activites':
              sectionCount++;
              sectionArr.push({
                title: block.field_title?.processed,
                section:
                  'section-' +
                  sectionCount,
              });

              let slides = [];
              globalText += `${block.field_title?.processed}.${block.body?.processed}`;
              block.relationships?.field_nos_activites?.forEach((item) => {
                slides.push({
                  image: getImage(
                    imagesArray,
                    item?.relationships?.field_image?.drupal_internal__mid
                  ),
                  info: {
                    text: item.title,
                    description: item.body?.summary,
                    link: item.field_link?.uri ? item.field_link?.uri : '/' + item.path?.langcode + item.path?.alias,
                    link_label: block.field_link_title,
                  },
                });
              });

              return (
                <section
                  className={classNames(
                    'section_content',
                    'block_2_section',
                    'section_slider_top_index',
                    'section-' +
                    sectionCount,
                  )}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block?.field_backgroud_color)}
                    bottomInfinite={block?.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}

                  >
                    <div className={classNames('wrapper_page_xs')}>
                      <TitleSection
                        title={block.field_title?.processed}
                        subtitle={block.field_subtitle?.processed}
                        type="arrow"
                        h2color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                      />
                    </div>
                    <div
                      className={classNames('wrapper_page_xs', 'only_desktop')}
                    >
                      <Carousel
                        slides={getReOrderedCarouselSlides(slides)}
                        sliderMode="title_description"
                        textColor={getTextColor(getSectionBG(block.field_backgroud_color))}
                      />
                    </div>
                    <div className={classNames('only_mobile_tablet')}>
                      <SliderComponent settings={slider_realisation_settings}>
                        {slides.map((slide, index) => (
                          <div key={index}>
                            <div className="only_mobile">
                              <div className="image-wrapper">
                                <img
                                  src={processUrl(slide.image?.image_style_uri?.gatsby_profile_thumbnail)}
                                  alt={`slide-${index}`}
                                  style={{
                                    width: '100%',
                                    height: '123vw',
                                    maxHeight: '510px',
                                  }}
                                />
                              </div>
                            </div>
                            <div className="only_tablet">
                              <img
                                src={processUrl(slide.image?.image_style_uri?.gatsby_profile_thumbnail)}
                                alt={`slide-${index}`}
                                style={{ objectFit: 'cover' }}
                              />
                            </div>
                            <div className="info_title_description">
                              <p>{slide.info.text}</p>
                              {slide.info.link && (
                                <Button
                                  primary={true}
                                  label={slide.info.link_label}
                                  link={slide.info.link}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                      </SliderComponent>
                    </div>
                  </ShapeBackground>
                </section>
              );
            case 'block_content__block_texte':
              if (block.field_title?.processed) {
                sectionCount++;
                sectionArr.push({
                  title: block.field_title?.processed,
                  section:
                    'section-' +
                    sectionCount,
                });
              }
              return (
                <section
                  className={classNames(
                    'section_content',
                    'section-' +
                    sectionCount,

                  )}
                  key={i}
                >
                  <ShapeBackground
                    top={tradDroiteGauche(block?.field_position_bordure_haut)}
                    color={getSectionBG(block?.field_backgroud_color)}
                    bottomInfinite={block?.field_infinite_border}
                    bottom={tradDroiteGauche(block?.field_position_bordure_bas)}
                  >
                    {block.body?.processed &&
                      block.relationships?.field_section.length === 0 ? (
                      <IntroPage
                        title={block.field_title?.processed}
                        subtitle={block.field_subtitle?.processed}
                        visuel={getImage(
                          imagesArray,
                          block?.relationships?.field_image
                            ?.drupal_internal__mid
                        )}
                        alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                        text={block.body?.processed}
                        size="border" //wrapper
                      />
                    ) : (
                      <>
                        <div className="wrapper_page_xs">
                          <TitleSection
                            title={block.field_title?.processed}
                            h2color={
                              block.field_backgroud_color === 'navy'
                                ? 'white'
                                : undefined
                            }
                          />
                        </div>
                        <VisuelTextOverlappedBlock
                          extremeLeft={true}
                          visuel={getImage(
                            imagesArray,
                            block?.relationships?.field_image?.drupal_internal__mid
                          )}
                          alt={block?.relationships?.field_image?.field_media_image?.alt || ""}
                          bgColor="color_sky_bleu"
                          text={block?.body?.processed}
                        />
                        <div className="wrapper_page_xs">
                          {block.relationships?.field_section?.map(
                            (section, index) => {
                              globalText +=
                                section?.relationships?.field_bloc_texte[0]
                                  ?.field_title?.processed +
                                '.' +
                                section?.relationships?.field_bloc_texte[0]
                                  ?.field_bloc_text_1?.processed;

                              return (
                                <TextVisuelTwoCol
                                  key={index}
                                  extremeLeft={
                                    section?.field_no_padding &&
                                      section?.field_alignment === 'left'
                                      ? true
                                      : false
                                  }
                                  extremeRight={
                                    section?.field_no_padding &&
                                      section?.field_alignment === 'right'
                                      ? true
                                      : false
                                  }
                                  orderInverse={
                                    section?.field_alignment === 'right'
                                      ? true
                                      : false
                                  }
                                  visuel={getImage(
                                    imagesArray,
                                    section?.relationships?.field_image
                                      ?.drupal_internal__mid
                                  )}
                                  alt={section.relationships?.field_image?.field_media_image?.alt || ''}
                                  title={
                                    section.relationships?.field_bloc_texte[0]
                                      ?.field_title?.processed
                                  }
                                  text={
                                    section.relationships?.field_bloc_texte[0]
                                      ?.field_bloc_text_1?.processed
                                  }
                                  colorTitle={
                                    block.field_backgroud_color === 'navy'
                                      ? 'color_white'
                                      : undefined
                                  }
                                  colorDescription={
                                    block.field_backgroud_color === 'navy'
                                      ? 'color_white'
                                      : undefined
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      </>
                    )}
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_chiffres_clefs':
              sectionCount++;
              sectionArr.push({
                title: block.field_title?.processed,
                section:
                  'section-' +
                  sectionCount,
              });
              const dataArr = [];
              block.relationships?.field_values?.forEach((item) => {
                dataArr.push({
                  number: item.field_chiffre,
                  title: item.field_legend?.processed,
                });
              });
              return (
                <KeyNumbers
                  key={i}
                  title={block.field_title?.processed}
                  subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                  sectionName={'section-' + sectionCount}
                  textColor="white"
                  backgroundImg={getImage(
                    imagesArray,
                    block.relationships?.field_image?.drupal_internal__mid
                  )}
                  datas={dataArr}
                  locale={locale}
                  NoPadding={true}
                />
              );
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query InnovationTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    pageData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_version_audio
          field_text_2 {
            processed
          }
          body {
            processed
          }
          field_padding
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  field_title_type
                  field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                  body {
                    processed
                  }
                  field_link {
                    title
                    url
                  }
                  field_alignment
                  field_infinite_border
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_no_padding
                  field_display_rtl
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_sites_web {
                      field_text {
                        processed
                      }
                      field_subtitle
                      field_link {
                        url
                      }
                      field_theme_style
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_actualites {
                  id
                  field_yellow_arrow
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  relationships {
                    field_anchor {
                      drupal_internal__tid
                    }
                  }
                }
                ... on block_content__block_video {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  field_link {
                    title
                    uri
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_background
                  langcode
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_slider_nos_activites {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_link_title
                  field_infinite_border
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__realisations {
                          title
                          body {
                            summary
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                          path {
                            alias
                            langcode
                          }
                          field_link{
                            uri
                            title
                          }
                        }
                        ... on node__activites {
                          title
                          body {
                            summary
                          }
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                          field_text_2 {
                            processed
                          }
                        }
                        ... on node__page{
													title
                          body{
														summary
                          }
                          path{
														alias
                            langcode
                          }
                          relationships{
														field_image{
															drupal_internal__mid
                              field_media_image{
                                alt
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_texte {
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_anchor {
                      drupal_internal__tid
                    }
                    field_section {
                      field_alignment
                      field_no_padding
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                        field_bloc_texte {
                          field_title {
                            processed
                          }
                          field_bloc_text_1 {
                            processed
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_chiffres_clefs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_values {
                      field_legend {
                        processed
                      }
                      field_chiffre
                    }
                  }
                }

              }
            }
          }
          field_type
          langcode
          path {
            alias
            langcode
          }
          drupal_id
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
  }
`;

export default InnovationProjets;
